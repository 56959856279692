// ==============================
// * [PARTIAL] VARIABLES
// Overrides app_storefront_base
// ? This file gathers all the Bootstrap variables overrides.
// ==============================
//
// FONTS PATHS
// COLORS
//    MISCELLANEOUS
//    GREYS
//    TRANSPARENT COLORS
//    BANNER COLORS
// BREAKPOINTS
// BORDER RADIUS
// BOX SHADOWS
// FONT-FAMILY
// FONT-SIZES
//    GLOBAL
//    TITLES
//    WEIGHTS
// INPUTS
// TABS
// BREADCRUMB
// TABLES
// SVG
// EFFECTS
//

@import "bootstrap/scss/functions";
@import "./abstract/functions";

// ----------------------------------------------------
// FONTS PATHS
// ----------------------------------------------------

// font-awesome font file locations in relation to target location of the css file.
$fa-font-path: "../fonts/font-awesome/" !default;

// flag icons location in relation to target location of the css file.
// $flag-icon-css-path: "../fonts/flags/" !default;

// Mulish font files location
$mulish-font-path: "../fonts/Mulish/" !default;


// ----------------------------------------------------
// COLORS
// ----------------------------------------------------

// MISCELLANEOUS
// ----------------------------------------------------

$white: #fff !default;
$black: #000 !default;
$blue: #5e7ff2 !default;
$green: #6bcb81 !default;
$red: #ed4d29 !default;
$purple: #555ae7 !default;
$pink: #ffa1bd !default;
$yellow: #f9f376 !default;

$primary: $black !default;
$secondary: $white !default;

$success: $green !default;
$danger: $red !default;

$state-danger-text: $danger !default;
$alert-success-text: $success !default;

$body-bg: $white !default;
$card-cap-bg: $white !default;

$border-color: $black !default;

$close-color: $black !default;

// GREYS
// ----------------------------------------------------

$grey1: #f6f7fc !default;
$grey2: #f8f8f4 !default;
$grey3: #e5e5e5 !default;
$grey4: #e5e5de !default;
$grey5: #a2a2a2 !default;
$grey6: #767676 !default;
$grey-disabled: #c8c8c8;

// DELETE LATER
$grey7: $black !default;
$grey8: $grey5 !default;

// TRANSPARENT COLORS
// ----------------------------------------------------

$grey-transparent-1: rgba(0, 0, 0, 0.1) !default;
$grey-transparent-2: rgba(0, 0, 0, 0.3) !default;

// BANNER COLORS
// ----------------------------------------------------

$banner-flat-1: #d4bcf0 !default;

$banner-linear-gradient-1: linear-gradient(180deg, #9afae9 0%, #e5feec 100%) !default;
$banner-linear-gradient-2: linear-gradient(180deg, #4f60db 0%, #d9bff0 100%) !default;
$banner-linear-gradient-3: linear-gradient(180deg, #e5feec 0%, #fcf27f 100%) !default;

$light-gray: $grey2 !default;
$slightly-darker-gray: $grey3 !default;
$dark-gray: $grey4 !default;
$darker-gray: $grey5 !default;
$horizontal-rule-grey: $black !default;
$product-number-grey: $grey3 !default;
$horizontal-border-grey: $grey4 !default;
$menu-link: $black !default;
$close-menu-bg: $white !default;
$link-color: $black !default;
$hr-border-color: $black !default;


// ----------------------------------------------------
// BREAKPOINTS
// ----------------------------------------------------

$device-small: 576px;
$device-medium: 768px;
$device-tablet-landscape: 992px;
$device-small-desktop: 1200px;
$device-x-large: 1400px;
$device-large-desktop: 2140px;

$grid-breakpoints: (
    xs: 0,
    sm: $device-small,
    md: $device-medium,
    lg: $device-tablet-landscape,
    dp: $device-small-desktop,
    xl: $device-x-large,
    xxl: $device-large-desktop
) !default;

$container-max-widths: (
    md: 720px,
    lg: 1220px,
    xl: 1440px) !default;

$search-container-max-width: 1180px;


// ----------------------------------------------------
// BORDER-RADIUS
// ----------------------------------------------------

$border-radius: 5px !default;
$border-radius-sm: 3px !default;
$border-radius-md: 10px !default;
$border-radius-lg: 20px !default;
$border-radius-xl: 50px !default; // Custom value
$border-radius-xxl: 70px !default; // Custom value
$border-radius-circle: 50% !default;

$rounded-pill: 290px !default;


// ----------------------------------------------------
// BOX SHADOWS
// ----------------------------------------------------

$box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);


// ----------------------------------------------------
// FONT-FAMILY
// ----------------------------------------------------

$font-family-sans-serif: "Mulish",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji" !default;

// ----------------------------------------------------
// FONT-SIZES
// ----------------------------------------------------

// GLOBAL
// -------------------------------------

$browser-base-pixel-size: 16px !default;
$font-size-base: rem(14px) !default;
$font-size-sm: rem(12px) !default;

// TITLES
// -------------------------------------

// DESKTOP
$font-size-big-h1-desktop: rem(80px) !default;
$font-size-h1-desktop: rem(40px) !default;
$font-size-h2-desktop: rem(24px) !default;
$font-size-h3-desktop: rem(20px) !default;

// MOBILE
$font-size-big-h1-mobile: rem(60px) !default;
$font-size-h1-mobile: rem(30px) !default;
$font-size-h2-mobile: rem(24px) !default;
$font-size-h3-mobile: rem(18px) !default;

// WEIGHTS
// -------------------------------------

$font-weight-light: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
$font-weight-black: 900 !default; // Custom value
$close-font-weight: 400 !default;

// MISCELLANEOUS
// -------------------------------------
$close-font-size: rem(36px) !default;


// ----------------------------------------------------
// INPUTS
// ----------------------------------------------------

$input-btn-padding-y: rem(10px) !default;
$input-btn-padding-x: rem(15px) !default;
$input-btn-focus-width: 0 !default;
$input-btn-focus-color: inherit !default;


// ----------------------------------------------------
// TABS
// ----------------------------------------------------

$nav-tabs-border-width: 0 !default;
$nav-tabs-border-radius: 0 !default;
$nav-tabs-active-link-hover-bg: transparent !default;


// ----------------------------------------------------
// BREADCRUMB
// ----------------------------------------------------

$breadcrumb-bg: transparent !default;


// ----------------------------------------------------
// TABLES
// ----------------------------------------------------

$table-border-color: $black !default;


// ----------------------------------------------------
// SVG
// ----------------------------------------------------

$svg-check: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") !default;

// ----------------------------------------------------
// EFFECTS
// ----------------------------------------------------

$duration-default: 0.3s;
$timing-function-default: ease-in-out;
