// ==============================
// * PAGE DESIGNER: SEO BLOCK
//
// ? This file gathers all the custom SEO Block CSS.
// ==============================

@import "../../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.seo-block {
    &__container {
        margin: $grid-gutter-width auto;

        @include media-breakpoint-up(lg) {
            margin: $grid-gutter-width * 2 auto;
        }
    }

    &__wrapper {
        max-width: 600px;
        margin: 0 auto;
        font-size: $font-size-sm;
    }

    &__links-container {
        display: flex;
        margin-top: $grid-gutter-width;
        padding-bottom: 10px;
        overflow-x: auto;

        @include media-breakpoint-up(lg) {
            display: block;
            margin-top: $grid-gutter-width * 2;
            padding: 0;
            overflow-x: hidden;
        }

        a {
            display: inline-block;
            margin-top: 10px;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            &:not(:last-of-type) {
                margin-right: 20px;
            }
        }
    }
}
